header {
  background: var(--color-neavy-blue);
}

.header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-main-link,
.header-right-link {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #ffffff;
  padding: 0px 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  text-decoration: none;
  cursor: pointer;
}

.header-main-link.active {
  color: var(--color-blue-diamond);
}

.header-main-link:last-of-type,
.header-right-link:last-of-type {
  border-right: 0px;
  padding-right: 0px;
}

.header-container {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 40px;
  margin-right: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
}

.main-header-logo-img {
  width: 60px;
}

.header-right-links {
  position: absolute;
  right: 0px;
}

@media (max-width: 1550px) {
  .header-right-links {
    position: relative;
  }

  .header-main-links {
    flex-grow: 1;
    text-align: right;
    margin-right: 50px;
  }
}