footer {
  background: var(--color-neavy-blue);
}

.footer-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-main-link,
.footer-right-link {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #ffffff;
  padding: 0px 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  text-decoration: none;
}

.footer-main-link.active {
  color: var(--color-blue-diamond);
}

.footer-main-link:last-of-type,
.footer-right-link:last-of-type {
  border-right: 0px;
  padding-right: 0px;
}

.footer-container {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 40px;
  margin-right: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
}

.main-footer-logo-img {
  width: 60px;
}

.footer-main-links {
  position: absolute;
  right: 0px;
}

.footer-copyright-container {
  position: relative;
  padding-top: 16px;
  padding-bottom: 25px;
  margin-left: 40px;
  margin-right: 40px;
}

.footer-copyright-desc {
  font-size: 14px;
  font-weight: 100;
  color: #ffffff;
}

.footer-social-links {
  position: absolute;
  right: 0px;
}

.footer-social-link {
  padding-left: 15px;
  padding-right: 15px;
}

.footer-social-link:last-of-type {
  padding-right: 0px;
}

.footer-copyright-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}