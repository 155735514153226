.basic-page-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.top-left-img {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 244px;
}

.bottom-right-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 193px;
}

.logo-img {
  width: 80px;
}

.basic-page-content {
  padding-top: 50px;
  text-align: center;
}