.user-custom-input__checkbox-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--color-neavy-blue);
  height: 28px;
  line-height: 28px;
}

.user-custom-input__checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.user-custom-input__checkbox-label .user-custom-input__checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  border: 1px solid var(--color-neavy-blue-10);
  border-radius: 5px;
}

.user-custom-input__checkbox-label:hover input ~ .user-custom-input__checkbox__checkmark {
  
}

.user-custom-input__checkbox-label input:checked ~ .user-custom-input__checkbox__checkmark {
  background-color: var(--color-neavy-blue);
}

.user-custom-input__checkbox-label .user-custom-input__checkbox__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.user-custom-input__checkbox-label input:checked ~ .user-custom-input__checkbox__checkmark:after {
  display: block;
}

.user-custom-input__checkbox-label .user-custom-input__checkbox__checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.user-custom-input-wrap {
  padding: 8px 16px;
  border: 1px solid var(--color-neavy-blue-50);
  border-radius: 5px;
  display: flex;
  align-items: center;
  background: #ffffff;
}

.user-custom-input-container input,
.user-custom-input-container select,
.user-custom-input-container textarea {
  color: var(--color-neavy-blue-50);
  font-size: 14px;
  outline: none;
  border: 0px;
  flex-grow: 1;
  width: 100%;
  resize: none;
  background: transparent;
}

.user-custom-input-container input::placeholder,
.user-custom-input-container select::placeholder,
.user-custom-input-container input::-webkit-input-placeholder,
.user-custom-input-container select::-webkit-input-placeholder,
.user-custom-input-container input::-moz-placeholder,
.user-custom-input-container select::-moz-placeholder,
.user-custom-input-container input::-ms-input-placeholder,
.user-custom-input-container select::-ms-input-placeholder {
  color: var(--color-neavy-blue-50);
}

.user-custom-input-label {
  margin-bottom: 8px;
  font-size: 15px;
  line-height: 16px;
  font-weight: 400;
  color: #082A46;
}

.user-custom-react-select__multi-value {
  background: var(--color-neavy-blue) !important;
  color: #ffffff !important;
}

.user-custom-react-select__multi-value__label {
  color: #ffffff !important;
}

.user-custom-react-select__control {
  border: 0px !important;
  text-align: left;
  font-size: 14px;
  min-height: initial !important;
  box-shadow: none !important;
}

.user-custom-react-select__indicator-separator {
  display: none;
}

.user-custom-react-select__value-container {
  padding: 0px !important;
  border: 0px !important;
}

.user-custom-react-select__input-container {
  border: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
  font-size: 14px;
  line-height: 16px;
}

.user-custom-react-select__placeholder {
  font-size: 14px;
  line-height: 16px;
  margin: 0px !important;
  color: var(--color-neavy-blue-50);
}

.user-custom-react-select__indicator {
  padding: 0px !important;
}

.user-custom-react-select__option {
  text-align: left;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--color-neavy-blue) !important;
}

.user-custom-react-select__option--is-focused,
.user-custom-react-select__option--is-selected {
  background: var(--color-neavy-blue) !important;
  color: #ffffff !important;
}

.css-b62m3t-container {
  border: 0px !important;
  width: 100%;
}

.user-custom-react-select__dropdown-indicator svg {
  width: 16px;
  height: 16px;
}

.user-custom-img-preview {
  margin-bottom: 20px;
  width: 300px;
  height: 300px;
  border: 1px solid var(--color-neavy-blue-50);
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-custom-img-preview img {
  width: 100%;
  height: 1005;
  object-fit: contain;
}