.about-section {
  background: var(--color-gray);
  position: relative;
  padding-top: 20px;
  padding-bottom: 70px;
}

.about-section .container {
  position: relative;
}

.about-section__img-desc-wrap {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.about-section__desc {
  color: var(--color-neavy-blue-50);
  font-size: 16px;
  line-height: 1.6;
}

.about-section__desc p {
  margin-bottom: 15px;
}

.about-section__target-img {
  position: absolute;
  left: 45px;
  top: 45px;
}

.about-section__plane-img {
  position: absolute;
  right: 10px;
  bottom: 0px;
}