.user-portal__logo-link-container {
  text-align: center;
  margin-bottom: 25px;
  margin-top: 30px;
  text-align: center;
}

.user-dashboard-content {
  background: #f5f5f5;
  margin: 0px 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 15px 15px;
}