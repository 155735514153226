.plan-section {
  text-align: center;
}

.plan-card-list {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-bottom: 30px;
}

.plan-period-container {
  margin-top: 20px;
  margin-bottom: 16px;
}