.checkout-section {
  padding-top: 75px;
  padding-bottom: 80px;
}

.back-btn {
  display: inline-block;
  cursor: pointer;
}

.back-btn-wrap {
  display: flex;
  align-items: center;
  gap: 6px;
}

.back-btn__text {
  font-size: 20px;
  font-weight: 700;
  color: var(--color-dark-black);
}

.checkout-section__content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 24px;
  margin-top: 20px;
}

.checkout-section__main {
  flex-grow: 1;
}

.checkout-section__side {
  width: 426px;
  flex-shrink: 0;
  flex-grow: 0;
}

.checkout-section__plan {
  padding: 35px 20px;
  background: var(--color-gray);
  position: relative;
}

.checkout-section__plan-price {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
}

.checkout-section__plan-price-value {
  font-size: 32px;
  font-weight: 700;
  color: var(--color-neavy-blue);
}

.checkout-section__plan-price-curreny {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-blue-diamond);
  margin-bottom: 5px;
  margin-left: 5px;
}

.checkout-section__plan-desc {
  opacity: .5;
  font-size: 22px;
  font-weight: 700;
  line-height: 140%;
  color: var(--color-neavy-blue);
  max-width: 245px;
}

.checkout-section__plan-level {
  padding: 9px 12px;
  background: var(--color-neavy-blue);
  color: var(--color-blue-diamond);
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  right: 20px;
  top: 33px;
}

.checkout-section__billing {
  margin-top: 20px;
}

.checkout-section__block-title {
  font-size: 20px;
  font-weight: 700;
  color: #161616;
  margin-bottom: 10px;
}

.checkout-section__summary {
  padding: 20px 15px;
  background: var(--color-gray);
  height: 100%;
}

.checkout-section__summary-price-table {
  margin-top: 20px;
}

.checkout-section__summary-price-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.checkout-section__summary-price-label {
  font-size: 18px;
  color: var(--color-dark-black);
}

.checkout-section__summary-price-value {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-neavy-blue);
}

.checkout-section__summary-price-value.value--large {
  font-size: 28px;
  font-weight: 700;
}

.checkout-section__summary-price-value.value--color-black {
  color: var(--color-dark-black)
}

.checkout-section__coupon-input-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  padding-left: 22px;
  background: #ffffff;
  border: 1px solid var(--color-neavy-blue-50);
}

.checkout-section__coupon-input {
  background: transparent;
  border: 0px;
  margin-right: 4px;
  color: var(--color-neavy-blue-50);
  outline: none;
  flex-grow: 1;
  width: 100%;
  resize: none;
  font-size: 14px;
  line-height: 20px;
}

.checkout-section__coupon-apply-btn {
  background: var(--color-neavy-blue);
  color: #fff;
  padding: 0px 36px;
  font-size: 16px;
  line-height: 48px;
  cursor: pointer;
}

.checkout-section__coupon-wrap hr {
  height: 1px;
  background: var(--color-neavy-blue);
  margin-top: 23px;
  margin-bottom: 11px;
  border: 0px;
}

.checkout-section__coupon-error {
  display: flex;
  align-items: flex-start;
}

.checkout-section__coupon-error-text {
  color: #f44f6c;
  font-size: 14px;
  line-height: 22px;
}

.checkout-section__coupon-error-icon {
  margin-right: 9px;
  margin-top: 3px;
}