:root {
  --color-gray: #F0F0F0;
  --color-dark-black: #00011D;
  --color-blue-diamond: #3AE2E0;
  --color-neavy-blue: #021B4D;
  --color-neavy-blue-70: rgba(2, 27, 77, 0.7);
  --color-neavy-blue-50: rgba(2, 27, 77, 0.5);
  --color-neavy-blue-10: rgba(2, 27, 77, 0.1);
}

* {
  padding: 0px;
  margin: 0px;
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.form-title {
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  color: var(--color-dark-black);
  margin-bottom: 5px;
}

.form-desc {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--color-neavy-blue-50);
}

.page-title {
  font-size: 26px;
  font-weight: 700;
  color: var(--color-neavy-blue);
  margin-bottom: 13px;
  text-transform: uppercase;
}

.page-sub-title {
  font-size: 20px;
  font-weight: 700;
  /* font-family: 'Outfit' */
  color: var(--color-neavy-blue);
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 10px;
}

.page-desc {
  font-size: 16px;
  line-height: 25px;
  color: var(--color-neavy-blue);
}

.form-row {
  margin-bottom: 16px;
}

.form-row-cols-2 {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
}

.form-row-cols-2 .form-col {
  width: 50%;
}

.mb-20 {
  margin-bottom: 20px;
}

.text-link {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-neavy-blue-50);
  text-decoration: none;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.bold-text-link {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: var(--color-neavy-blue);
  text-decoration: none;
}

.login-form-wrap {
  width: 548px;
  margin-top: 100px;
}

.setpassword-form-wrap {
  width: 548px;
  margin-top: 100px;
}

.signup-form-wrap {
  width: 797px;
  margin-top: 50px;
}

.contact-form-wrap {
  width: 690px;
  margin: auto;
  margin-bottom: 90px;
}

.contact-form-wrap .form-content {
  margin-top: 0px;
}

.form-content  {
  margin-top: 26px;
}

.form-wrap {
  background: var(--color-gray);
  padding: 50px;
}

.choose-plan-wrap {
  width: 80%;
  margin: auto;
}

.container {
  max-width: 1126px;
  margin: auto;
}

.color-white {
  color: #ffffff;
}

.image-links {
  display: flex;
  justify-content: center;
  gap: 33px;
}

.image-link-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-gray);
  border-radius: 20px;
  width: 290px;
  height: 260px;
  text-decoration: none;
}

.image-link-wrap__label {
  font-size: 24px;
  font-weight: 600;
  line-height: 22px;
  color: #000000;
  margin-left: 15px;
  text-transform: capitalize;
}

.logo-link {
  display: inline-block;
}

.login-signup-links {
  margin-top: calc(50vh - 270px);
}

.response-message {
  margin-bottom: 10px;
}