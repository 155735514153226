.teammate-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}

.teammate-wrap {
  background: #fff;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
}

.teammate-info-row {
  display: flex;
  justify-content: space-between;
}

.teammate-logo {
  max-width: 200px;
  margin: auto;
}

.teammate-search-wrap {
  padding: 20px;
}