.account-nav-link {
  display: flex;
  align-items: center;
  padding: 13px 17px;
  cursor: pointer;
}

.account-nav-link.active {
  background: var(--color-neavy-blue);
  border-radius: 10px;
}

.account-nav-link-label {
  font-size: 14px;
  line-height: 20px;
  color: #3f3f3f;
  margin-left: 14px;
}

.account-nav-link.active .account-nav-link-label {
  color: #ffffff;
}

.user-account__body {
  display: flex;
  gap: 30px;
  margin-left: 20px;
  margin-right: 20px;
}

.user-account__side {
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  width: 230px;
}

.user-account-page {
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
}

.user-account-page-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-neavy-blue);
  margin-bottom: 16px;
}

.user-account__main {
  flex-grow: 1;
}