.custom-button {
  padding: 16px;
  text-align: center;
  color: var(--color-neavy-blue);
  font-size: 16px;
  font-weight: 500;
  background: var(--color-blue-diamond);
  outline: none;
  border: 0px;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  clip-path: polygon(0 0, calc(100% - 8px) 0, 100% 8px, 100% 100%, 0 100%);
  text-decoration: none;
  display: inline-block;
}

.custom-button--w100 {
  width: 100%;
}