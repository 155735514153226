.user-learn__filter-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: var(--color-neavy-blue);
  padding-bottom: 13px;
  border-bottom: 1px solid var(--color-neavy-blue-10);
}

.user-learn__filter-wrap {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
}

.user-learn__filters {
  margin-top: 7px;
}

.user-learn__filter {
  cursor: pointer;
  padding: 7px 0px;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-neavy-blue-50);
}

.user-learn__filter.active {
  font-weight: bold;
}

.user-learn__body {
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  gap: 30px;
}

.user-learn__side {
  width: 260px;
  flex-shrink: 0;
}

.user-learn__videos-grid {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(5, 1fr);
}

.user-learn__main {
  flex-grow: 1;
}