.content-item-wrap {
  border-radius: 10px;
  background: #ffffff;
  padding: 16px;
  margin-bottom: 20px;
}

.content-item-wrap:last-of-type {
  margin-bottom: 0px;
}

.content-item-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.content-item__cell {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.content-item__cell-label {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: var(--color-neavy-blue);
  min-width: 100px;
}

.content-item__cell-value {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-neavy-blue-50);
}