.plan-card-wrap {
  background: var(--color-gray);
  padding: 10px;
  max-width: 290px;
  box-sizing: border-box;
  text-align: left;
  padding-bottom: 40px;
}

.plan-card__level-wrap {
  text-align: right;
  margin-bottom: 5px;
}

.plan-card__level {
  background: var(--color-neavy-blue);
  color: var(--color-blue-diamond);
  padding: 9px 12px;
  font-size: 12px;
  line-height: 1.3;
  text-align: center;
}

.plan-card__price-value {
  color: var(--color-neavy-blue);
  font-size: 32px;
  font-weight: bold;
  line-height: 1.3;
}

.plan-card__currency {
  font-size: 14px;
  line-height: 1.3;
  font-weight: 600;
  color: var(--color-blue-diamond);
  margin-left: 5px;
}

.plan-card__price-wrap {
  margin-bottom: 20px;
}

.plan-card__description {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-neavy-blue-50);
  margin-bottom: 30px;
}

.plan-card__btn {
  position: relative;
  padding: 15px 30px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  background: var(--color-dark-black);
  text-decoration: none;
  display: inline-block;
}

.plan-card__btn::after {
  content: '';
  position: absolute;
  right: 0px;
  top: 0px;
  border-top: 5px solid var(--color-gray);
  border-right: 5px solid var(--color-gray);
  border-left: 5px solid transparent;
  border-bottom: 5px solid transparent;
}