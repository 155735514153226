.custom-input-container {
  padding: 15px 20px;
  background: #ffffff;
  border: 1px solid var(--color-neavy-blue-50);
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

.custom-input-icon {
  margin-right: 10px;
  opacity: .5;
}

.custom-input-container input,
.custom-input-container select,
.custom-input-container textarea {
  color: var(--color-neavy-blue-50);
  outline: none;
  border: 0px;
  flex-grow: 1;
  width: 100%;
  resize: none;
  font-size: 14px;
  line-height: 20px;
}

.custom-input-end-icon {
  margin-left: 10px;
  opacity: .5;
}

.custom-input-container > div {
  flex-grow: 1;
}

.custom-react-select__multi-value {
  background: var(--color-neavy-blue) !important;
  color: #ffffff !important;
}

.custom-react-select__multi-value__label {
  color: #ffffff !important;
}

.custom-react-select__control {
  border: 0px !important;
  text-align: left;
  font-size: 14px;
  min-height: initial !important;
  box-shadow: none !important;
}

.custom-react-select__indicator-separator {
  display: none;
}

.custom-react-select__value-container {
  padding: 0px !important;
  border: 0px !important;
}

.custom-react-select__input-container {
  border: 0px !important;
  margin: 0px !important;
  padding: 0px !important;
  font-size: 14px;
  line-height: 20px;
}

.custom-react-select__placeholder {
  font-size: 14px;
  line-height: 20px;
  margin: 0px !important;
  color: var(--color-neavy-blue-50);
}

.custom-react-select__indicator {
  padding: 0px !important;
}

.custom-react-select__option {
  text-align: left;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--color-neavy-blue) !important;
}

.custom-react-select__option--is-focused,
.custom-react-select__option--is-selected {
  background: var(--color-neavy-blue) !important;
  color: #ffffff !important;
}

.css-b62m3t-container {
  border: 0px !important;
}