.user-partner-marketplace__body {
  margin-left: 20px;
  margin-right: 20px;
}

.user-partner-marketplace__head {
  position: relative;
  width: 100%;
  height: 0px;
  padding-top: 10.57%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.user-partner-marketplace__profile-img {
  border-radius: 50%;
  width: 135px;
  height: 135px;
  border: 5px solid #ffffff;
  position: absolute;
  bottom: 0px;
  left: 40px;
  transform: translateY(50%);
}

.user-partner-marketplace__logo-img {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.user-partner-marketplace__contact-btn-wrap {
  text-align: right;
  margin-top: 15px;
}

.user-partner-marketplace__search-bar {
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
  gap: 10px;
}

.user-partner-marketplace-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-neavy-blue);
  margin-bottom: 15px;
}

.user-partner-marketplace__infos {
  display: flex;
  margin-bottom: 30px;
}

.user-partner-marketplace__info-col {
  padding: 0px 30px;
  position: relative;
}

.user-partner-marketplace__info-col:first-of-type {
  padding-left: 0px;
}

.user-partner-marketplace__info-col::after {
  content: '';
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 0px;
  width: 1px;
  background: #d0d0d0;
}

.user-partner-marketplace__info-label {
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: 1px;
  color: var(--color-dark-black);
  margin-bottom: 3px;
}

.user-partner-marketplace__info-value {
  font-size: 16px;
  line-height: 1.6;
  font-weight: 100;
  color: var(--color-neavy-blue-70);
}

.user-partner-marketplace-sub-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--color-neavy-blue);
}

.user-partner-marketplace-payment-btns {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.user-parnter-marketplace-block {
  position: relative;
  margin-bottom: 70px;
}