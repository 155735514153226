.video-item__feature {
  width: 100%;
  height: 0px;
  padding-top: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.video-item__play-btn-img {
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.video-item__label {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: var(--color-neavy-blue);
}