.plan-period-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan-period-name {
  font-size: 16px;
  line-height: 25px;
  color: var(--color-neavy-blue);
  font-weight: 100;
  text-transform: capitalize;
  width: 57px;
}

.plan-period-name--active {
  font-weight: 700;
}

.plan-period__radio-dot {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: var(--color-neavy-blue);
  box-shadow: 1px 1px 3px 0px #081420;
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 3px;
}

.plan-period__radio-dot--right {
  left: initial;
  right: 3px;
}

.plan-period__radio {
  width: 44px;
  height: 25px;
  border-radius: 20px;
  background: var(--color-blue-diamond);
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin: 0px 10px;
}

.plan-period-save {
  background: var(--color-neavy-blue);
  text-align: center;
  padding: 2px 10px;
  border-radius: 20px;
  color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  margin-left: 16px;
  display: inline-block;
}