.label-section {
  padding-top: 70px;
  padding-bottom: 20px;
}

.label-section-wrap {
  position: relative;
  text-align: center;
}

.label-front {
  font-size: 26px;
  font-weight: 700;
  color: var(--color-neavy-blue);
  letter-spacing: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
}

.label-behind {
  font-size: 100px;
  font-weight: 800;
  color: var(--color-neavy-blue);
  letter-spacing: 10px;
  opacity: 0.05;
  text-transform: uppercase;
}