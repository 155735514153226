.user-custom-button-wrap {
  display: inline-block;
}

.user-custom-button {
  font-size: 16px;
  padding: 13px 26px;
  border-radius: 10px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-custom-button.user_custom_button_type_fill {
  background: var(--color-neavy-blue);
  color: #ffffff;
}

.user-custom-button.user_custom_button_type_outline {
  color: var(--color-neavy-blue);
  border-color: var(--color-neavy-blue);
}

.user-custom-input-icon {
  margin-right: 10px;
}