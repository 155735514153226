.explorer-section {
  position: relative;
  background: var(--color-neavy-blue);
  padding-top: 45px;
  text-align: center;
  font-size: 0px;
}

.explorer-section .page-title {
  letter-spacing: 10px;
  max-width: 870px;
  margin: auto;
  margin-bottom: 15px;
}

.explorer-section__main-img {
  margin-top: 30px;
}

.explorer-section__satellite-img {
  position: absolute;
  left: 25px;
  top: 32px;
  transform: rotate(10deg);
}

.explorer-section__target-img {
  position: absolute;
  right: 100px;
  bottom: 70px;
}