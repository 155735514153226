.head-section {
  background: var(--color-neavy-blue);
  padding-top: 70px;
  padding-bottom: 70px;
  text-align: center;
}

.head-section__title {
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 10px;;
}