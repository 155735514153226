.home-sider__slide {
  padding-top: 130px;
  padding-bottoM: 180px;
  position: relative;
}

.home-sider__slide::before {
  content: '';
  background: linear-gradient(to right, rgba(2, 27, 77, 1), rgba(2, 27, 77, 0));
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.home-sider__slide .container {
  position: relative;
}

.home-slider__slide-wrap {
  max-width: 784px;
}

.home-slider__sub-title {
  text-transform: uppercase;
  color: var(--color-blue-diamond);
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: 10px;
  font-weight: 600;;
}

.home-slider__main-title {
  color: #ffffff;
  font-size: 55px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 10px;
}

.home-slider__desc {
  font-size: 16px;
  line-height: 1.6;
  color: #ffffff;
  margin-bottom: 20px;
}

.home-slider__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

.home-slider__arrow--prev {
  left: 57px;
}

.home-slider__arrow--next {
  right: 57px;
}