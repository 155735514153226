.sub-page-tabs-container {
  display: flex;
  justify-content: center;
  gap: 17px;
  margin-bottom: 30px;
}

.sub-page-tab-link {
  display: flex;
  padding: 13px 33px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--color-gray);
  text-decoration: none;
  color: var(--color-dark-black);
  min-width: 195px;
}

.sub-page-tab-link.active {
  background: var(--color-neavy-blue);
  color: #ffffff;
}


.sub-page-tab-link__text {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-left: 14px;
}