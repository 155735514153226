.user-search__body {
  display: flex;
  gap: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.user-search__side {
  width: 260px;
}

.user-search__filter {
  padding: 16px 10px;
  background: #ffffff;
  border-radius: 10px;
}

.user-search__filter__title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: var(--color-neavy-blue);
  margin-bottom: 13px;
}

.user-search__filter-group__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  border-top: 1px solid var(--color-neavy-blue-10);
  cursor: pointer;
}

.user-search__filter-group__title {
  font-size: 18px;
  line-height: 16px;
  font-weight: 400;
  color: var(--color-neavy-blue);
}

.user-search__filter-group__sub-title {
  font-size: 16px;
  line-height: 16px;
  color: var(--color-neavy-blue-50);
  margin-bottom: 15px;
  margin-top: 10px;
}

.user-search__main {
  flex-grow: 1;
}

.user-search__search-bar {
  display: flex;
  gap: 11px;
  margin-bottom: 20px;
}

.user-search__search-bar__country {
  width: 176px;
}

.user-search__search-bar__input {
  flex-grow: 1;
}