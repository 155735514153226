.dashboard-nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px 0px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 8px;
  min-width: 250px;
}

.dashboard-nav-link.active {
  background: var(--color-blue-diamond);
}

.dashboard-nav-link__text {
  font-size: 16px;
  line-height: 19.6px;
  font-weight: 500;
  margin-left: 10px;
}

.user-dashboard__navbar {
  display: flex;
  justify-content: center;
  background: var(--color-neavy-blue);
  padding: 12px;
  gap: 65px;
  border-radius: 10px 10px 0px 0px;
}

.user-dashboard__body {
  display: flex;
  justify-content: space-between;
  padding: 35px 20px;
  padding-bottom: 0px;
  gap: 20px;
}

.user-dashboard__summary__title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: var(--color-neavy-blue);
  padding-bottom: 13px;
  border-bottom: 1px solid var(--color-neavy-blue-10);
}

.user-dashboard__summary {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
  max-width: 200px;
}

.user-dashboard__summary__filters {
  margin-top: 7px;
}

.user-dashboard__summary__filter {
  cursor: pointer;
  padding: 7px 0px;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-neavy-blue-50);
}

.user-dashboard__summary__filter.active {
  font-weight: bold;
}

.user-dashboard__center {
  flex-grow: 1;
}

.user-dashboard__event {
  height: 100%;
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  line-height: 24px;
  font-weight: 400;
  color: var(--color-neavy-blue);
  min-width: 230px;
}

.my-teammate-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}